import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail, // Import the reset function
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../Config/firebase"; // Firebase auth
import axios from "axios"; // Axios for API calls
import config from "../Config/config";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import ToastAlert, { showToast } from "../Common/ToastAlert";

const LandingPage = () => {
  const [showLogin, setShowLogin] = useState(true);
  const navigate = useNavigate();
  return (
    <div className="flex w-screen h-screen bg-gradient-to-r from-purple-600 to-blue-500 text-gray-800 flex-col md:flex-row">

      {/* Left side: Information about Listalot */}
      <ToastAlert />
      <div className="flex flex-col justify-center items-center w-full md:w-1/2 h-full bg-white p-4">
        <img src={logo} className="w-[250px] h-[250px]" alt="Logo" />
        <h1 className="text-4xl font-bold text-primary text-center">Bulk AI Cataloging Software</h1>
        <p className="text-lg text-primary opacity-70 leading-relaxed text-center max-w-md my-2">
          Simply add your photos and Listernaut will write your titles,
          descriptions, categories, item numbers, dimensions, weights,
          serial numbers, model numbers, and much more.
          Create an AI tone for perfect titles and descriptions every time.
        </p>
        <button
          type="submit"
          onClick={() => navigate("/submitrequest")}
          className="w-[300px] px-4 py-2 my-3 bg-primary text-lg text-white rounded-full font-semibold hover:bg-blue-700 transition bg-opacity-90"
        >
          Submit A Request
        </button>
        <h1 className="text-xl font-bold text-primary">FULL-SERVICE</h1>
        <h1 className="text-lg font-bold text-primary">24-48 hour turnaround</h1>
      </div>

      {/* Vertical separator line */}
      <div className="hidden md:block w-0.5 h-full bg-gray-300"></div>

      {/* Right side: Login or Signup form */}
      <div className="flex flex-col justify-center items-center w-full md:w-1/2 h-full bg-primary px-4 py-4">
        <h1 className="text-3xl font-bold text-white">SELF-SERVICE</h1>
        <h1 className="text-xl font-bold text-white mb-2 opacity-70">No Waiting - Instant Results</h1>
        <div className="w-full max-w-sm bg-white p-8 rounded-lg shadow-lg">
          <div className="flex justify-center space-x-4 mb-6">
            <button
              className={`w-1/2 px-4 py-2 rounded-full font-semibold transition ${showLogin ? "bg-bcolor text-white shadow-md" : "bg-gray-200 text-gray-700"
                }`}
              onClick={() => setShowLogin(true)}
            >
              Login
            </button>
            <button
              className={`w-1/2 px-4 py-2 rounded-full font-semibold transition ${!showLogin ? "bg-bcolor text-white shadow-md" : "bg-gray-200 text-gray-700"
                }`}
              onClick={() => setShowLogin(false)}
            >
              Signup
            </button>
          </div>
          <div className="transition-all duration-500 ease-in-out transform">
            {showLogin ? <StyledLogin /> : <StyledSignup />}
          </div>
        </div>
      </div>
    </div>
  );
};

// Styled Login Component with Forgot Password option
const StyledLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await userCredential.user.getIdToken();

      const response = await axios.post(`${config.API_BASE_URL}/login`, {
        email,
        idToken,
      });
      console.log(response.data);
      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("email", response.data.user.email);
      localStorage.setItem("username", response.data.user.username);
      setError("");
    } catch (err) {
      console.error("Login error:", err);
      setError("Failed to log in. Please check your credentials.");
    }
  };

  // Handle forgot password
  const handleForgotPassword = async () => {
    if (!forgotEmail) {
      showToast("Please enter your email.", "warning");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, forgotEmail);
      showToast("Password reset link has been sent. Check your email.", "success");
      setForgotPasswordOpen(false);
      setForgotEmail("");
    } catch (err) {
      console.error("Forgot password error:", err);
      showToast("Failed to send password reset email.", "error");
    }
  };

  return (
    <>
      <form className="flex flex-col space-y-4" onSubmit={handleLogin}>
        <div>
          <label className="block text-gray-700 font-semibold mb-1">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 rounded-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
            placeholder="Enter your email"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-1">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 rounded-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
            placeholder="Enter your password"
          />
        </div>
        {/* Forgot Password Link */}
        <div className="text-right">
          <button
            type="button"
            className="text-sm text-primary hover:underline focus:outline-none"
            onClick={() => setForgotPasswordOpen(true)}
          >
            Forgot Password?
          </button>
        </div>
        {error && <p className="text-red-600 text-sm">{error}</p>}
        <button
          type="submit"
          className="w-full px-4 py-2 mt-4 bg-bcolor text-white rounded-full font-semibold hover:bg-blue-700 transition"
        >
          Login
        </button>
      </form>

      {/* Forgot Password Modal */}
      <Dialog open={forgotPasswordOpen} onClose={() => setForgotPasswordOpen(false)}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Enter your email"
            value={forgotEmail}
            onChange={(e) => setForgotEmail(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <Typography variant="body2" color="textSecondary">
            A password reset link will be sent to your email.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setForgotPasswordOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleForgotPassword} color="primary">
            Send Reset Link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// Styled Signup Component
const StyledSignup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!email || !password || !name) {
      setError("All fields are required.");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user.uid;

      const response = await axios.post(`${config.API_BASE_URL}/signup`, {
        email,
        username: name,
        userId: user,
      });

      localStorage.setItem("userId", user);
      localStorage.setItem("email", email);
      localStorage.setItem("username", name);
      setError("");
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        setError("This email is already registered. Please log in instead.");
      } else {
        setError("Failed to sign up. Please check your details.");
      }
      console.error("Signup error:", err.message);
    }
  };

  return (
    <form className="flex flex-col space-y-4" onSubmit={handleSignup}>
      <div>
        <label className="block text-gray-700 font-semibold mb-1">Full Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-4 py-2 rounded-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
          placeholder="Enter your full name"
        />
      </div>
      <div>
        <label className="block text-gray-700 font-semibold mb-1">Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-4 py-2 rounded-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
          placeholder="Enter your email"
        />
      </div>
      <div>
        <label className="block text-gray-700 font-semibold mb-1">Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-4 py-2 rounded-full border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition"
          placeholder="Create a password"
        />
      </div>
      {error && <p className="text-red-600 text-sm">{error}</p>}
      <button
        type="submit"
        className="w-full px-4 py-2 mt-4 bg-bcolor text-white rounded-full font-semibold hover:bg-blue-700 transition"
      >
        Signup
      </button>
    </form>
  );
};

export default LandingPage;