import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../Config/config"; // Exports API_BASE_URL
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { showToast } from "../Common/ToastAlert";
import JSZip from "jszip";
import { saveAs } from "file-saver";

// Helper: determine createdAt text color based on age in days
function getCreatedAtColor(createdAt) {
  const now = new Date();
  const created = new Date(createdAt);
  const diffDays = (now - created) / (1000 * 60 * 60 * 24);
  if (diffDays < 0) return "grey";
  if (diffDays < 3) return "green";
  if (diffDays < 5) return "#fbbf24";
  if (diffDays < 7) return "red";
  return "grey";
}

// Helper: determine status text color
function getStatusColor(status) {
  if (status === "pending") return "orange";
  if (status === "completed") return "green";
  if (status === "deleted") return "red";
  return "black";
}

function SubmittedRequests() {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  // Sorting state
  const [order, setOrder] = useState("desc"); // "asc" or "desc"
  const [orderBy, setOrderBy] = useState("createdAt"); // "podName", "status", "createdAt", "totalImages"

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Fetch requests from the backend
  const fetchRequests = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/get-requests`);
      setRequests(response.data.requests || []);
    } catch (err) {
      setError(err.message || "Failed to fetch requests.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  // Sorting handler: toggles order for the given property.
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Compute sorted requests based on order and orderBy.
  const sortedRequests = [...requests].sort((a, b) => {
    let cmp = 0;
    if (orderBy === "podName") {
      cmp = (a.podName || "").localeCompare(b.podName || "");
    } else if (orderBy === "status") {
      cmp = (a.status || "").localeCompare(b.status || "");
    } else if (orderBy === "createdAt") {
      cmp = new Date(a.createdAt) - new Date(b.createdAt);
    } else if (orderBy === "totalImages") {
      cmp =
        (a.images ? a.images.length : 0) - (b.images ? b.images.length : 0);
    }
    return order === "asc" ? cmp : -cmp;
  });

  // Apply pagination to sorted requests.
  const displayedRequests = sortedRequests.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Update request status via API
  const updateRequestStatus = async (requestId, action) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/update-request`, {
        requestId,
        action,
      });
      if (response.status === 200) {
        showToast(`Request ${action} successfully!`, "success");
        fetchRequests();
      }
    } catch (error) {
      console.error("Error updating request:", error.message);
      showToast("Failed to update request.", "error");
    }
  };

  // Download images as a ZIP file using JSZip and FileSaver
  const downloadImages = async (podname, name, images, limit = 500) => {
    const zip = new JSZip();
    const folder = zip.folder(`${name}_${podname}`);
    let activeDownloads = 0;
    let index = 0;
    const downloadNext = () => {
      if (index >= images.length) return Promise.resolve();
      const currentIndex = index++;
      activeDownloads++;
      return fetch(images[currentIndex])
        .then((response) => response.blob())
        .then((blob) => {
          const ext = blob.type.split("/")[1] || "jpg";
          folder.file(`image_${currentIndex + 1}.${ext}`, blob);
        })
        .catch((error) => {
          console.error(`Error downloading image ${currentIndex}:`, error);
          showToast("Failed to download some images.", "error");
        })
        .finally(() => {
          activeDownloads--;
        });
    };
    const tasks = [];
    for (let i = 0; i < limit; i++) {
      tasks.push(
        (async function run() {
          while (index < images.length) {
            await downloadNext();
          }
        })()
      );
    }
    await Promise.all(tasks);
    try {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${name}_${podname}.zip`);
    } catch (error) {
      console.error("Error generating ZIP:", error);
      showToast("Failed to generate ZIP file.", "error");
    }
  };

  // Open the details modal for a request
  const handleViewDetails = (req) => {
    setSelectedRequest(req);
    setOpenDetails(true);
  };

  // Close the details modal
  const handleCloseDetails = () => {
    setOpenDetails(false);
    setSelectedRequest(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-4">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <h1 className="text-center text-primary text-4xl font-bold">Admin Panel</h1>
      <h1 className="text-center text-primary flex justify-end">
        Tokens Remaining: <strong>{localStorage.getItem("tokens")}</strong>
      </h1>
      <div className="h-1 w-full bg-primary my-2"></div>
  
      {requests.length === 0 ? (
        <Typography variant="body1">No requests submitted yet.</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pod Name</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "status"}
                      direction={orderBy === "status" ? order : "asc"}
                      onClick={() => handleRequestSort("status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "createdAt"}
                      direction={orderBy === "createdAt" ? order : "asc"}
                      onClick={() => handleRequestSort("createdAt")}
                    >
                      Created At
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "totalImages"}
                      direction={orderBy === "totalImages" ? order : "asc"}
                      onClick={() => handleRequestSort("totalImages")}
                    >
                      Total Images
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedRequests.map((req) => {
                  const createdAtColor = getCreatedAtColor(req.createdAt);
                  return (
                    <TableRow key={req.requestId}>
                      <TableCell sx={{ color: "#333999" }}>
                        {req.podName}
                      </TableCell>
                      <TableCell>
                        <span
                          style={{
                            color: getStatusColor(req.status),
                            fontWeight: "bold",
                          }}
                        >
                          {req.status === "uploading" ? "Uploading..." : req.status}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ color: createdAtColor }}>
                          {req.createdAt ? new Date(req.createdAt).toLocaleString() : "N/A"}
                        </span>
                      </TableCell>
                      <TableCell>{req.images ? req.images.length : 0}</TableCell>
                      <TableCell>
                        <Stack direction="column" spacing={1}>
                          <button
                            className="bg-primary text-white px-4 py-2 rounded-md"
                            onClick={() => handleViewDetails(req)}
                          >
                            View
                          </button>
                          {req.status === "pending" && (
                            <button
                              className="bg-primary text-white px-4 py-2 rounded-md"
                              onClick={() =>
                                updateRequestStatus(req.requestId, "done")
                              }
                            >
                              Mark Completed
                            </button>
                          )}
                          {req.images && req.images.length > 0 && (
                            <button
                              className="bg-primary text-white px-4 py-2 rounded-md"
                              onClick={() =>
                                downloadImages(req.podName, req.name, req.images)
                              }
                            >
                              Download Images
                            </button>
                          )}
                          <button
                            className="bg-delete text-white px-4 py-2 rounded-md"
                            onClick={() =>
                              updateRequestStatus(req.requestId, "delete")
                            }
                          >
                            Delete
                          </button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Table Pagination */}
          <TablePagination
            component="div"
            count={sortedRequests.length}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </>
      )}

      {/* Details Modal */}
      <Dialog
        open={openDetails}
        onClose={handleCloseDetails}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Request Details</DialogTitle>
        <DialogContent dividers>
          {selectedRequest && (
            <>
              <DialogContentText>
                <strong>Pod Name:</strong> {selectedRequest.podName}
              </DialogContentText>
              <DialogContentText>
                <strong>Name:</strong> {selectedRequest.name}
              </DialogContentText>
              <DialogContentText>
                <strong>Email:</strong> {selectedRequest.email}
              </DialogContentText>
              <DialogContentText>
                <strong>Phone:</strong> {selectedRequest.phone}
              </DialogContentText>
              <DialogContentText>
                <strong>Subscription ID:</strong>{" "}
                {selectedRequest.subscriptionId || "N/A"}
              </DialogContentText>
              <DialogContentText>
                <strong>Custom Description Tone:</strong>{" "}
                {selectedRequest.customDescriptionTone || "N/A"}
              </DialogContentText>
              <DialogContentText>
                <strong>Custom Title Tone:</strong>{" "}
                {selectedRequest.customTitleTone || "N/A"}
              </DialogContentText>
              <DialogContentText>
                <strong>Initial Lot Number:</strong>{" "}
                {selectedRequest.initialLotNumber}
              </DialogContentText>
              <DialogContentText>
                <strong>Additional Info:</strong>{" "}
                {selectedRequest.additionalInfo || "N/A"}
              </DialogContentText>
              <DialogContentText>
                <strong>Total Images:</strong>{" "}
                {selectedRequest.images ? selectedRequest.images.length : 0}
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubmittedRequests;