import React from "react";

const Home = () => {
  return (
    <div className="flex flex-col  ">
      
    <h1 className="text-4xl text-primary text-center">Welcome to <span className="font-bold">LISTERNAUT </span> Bulk Uploader</h1>
    <h1 className="text-center text-primary flex my-2 justify-end">
            Tokens Remaining: <strong>{localStorage.getItem("tokens")}</strong>
            </h1>
    <div className="h-1 w-full bg-primary my-5"></div>
    <h1 className="text-2xl text-primary mt-10">If you would like a free walk through and training, please <button className="font-bold" onClick={() => window.open("https://meetings.hubspot.com/jeff1270", "_blank")}>Click Here. </button> </h1>
   <h1 className="w-[70%] text-primary text-2xl mt-10">
   <span className="font-bold"> To Start:</span> Visit Settings to Link your User Account, Buy Lookups, or
customize an AI Tone writer for your titles and descriptions. If you
are using a pre-set tone, or exporting to a connected account, please
proceed to Catalog with Photos or with Spreadsheet.
   </h1>
   <h1 className="w-[70%] text-primary text-2xl mt-10"> Thank you for choosing Listernaut, we appreciate you.</h1>
    </div>
  );
};

export default Home;