import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { auth } from "./Components/Config/firebase"; // Import your Firebase auth instance
import LandingPage from "./Components/Common/LandingPage";
import Sidebar from "./Components/Common/Navbar";
import SubmitRequest from "./Components/SubmitRequest/SubmitRequest";
import SubmittedRequests from "./Components/SubmitRequest/SubmittedRequests";
import Home from "./Components/Common/Home";


const Admin = lazy(() => import("./Components/Common/Admin"));
const CreatePods = lazy(() => import("./Components/AI/CreatePods"));
const ManualLotCreator = lazy(() => import("./Components/AI/ManualLotCreator"));
const BulkLotCreator = lazy(() => import("./Components/AI/BulkLotCreator"));
const Results = lazy(() => import("./Components/AI/Results"));
const BarcodeLookup = lazy(() => import("./Components/Barcode/BarcodeLookup"));
const UserSettings = lazy(() => import("./Components/Common/UserSettings"));

const LoadingIndicator = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontSize: "18px",
        color: "#555",
      }}
    >
      Loading...
    </div>
  );
};

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
      setLoading(false); // Stop loading once the auth state is determined
    });
    return unsubscribe; // Cleanup the subscription
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route
          path="/"
          element={user ? <Dashboard /> : <LandingPage />}
        />
        <Route
          path="/submitrequest"
          element={<SubmitRequest />}
        />
        {/* Protected Routes */}
        <Route
          path="/*"
          element={
            user ? (
              <Suspense fallback={<LoadingIndicator />}>
                <Dashboard />
              </Suspense>
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

const Dashboard = () => {
  return (
    <div style={{ display: "flex" }} className="font-gill max-h-screen overflow-scroll">
      <Sidebar />
      <div style={{ padding: "16px", flex: 1 }}>
        <Routes>

          <Route
            path=""
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="createpods"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <CreatePods />
              </Suspense>
            }
          />
          <Route
            path="barcodelookup"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <BarcodeLookup />
              </Suspense>
            }
          />
          <Route
            path="usersettings"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <UserSettings />
              </Suspense>
            }
          />
          <Route
            path="manual"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <ManualLotCreator />
              </Suspense>
            }
          />
          <Route
            path="admin"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <Admin />
              </Suspense>
            }
          />
          <Route
            path="bulk"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <BulkLotCreator />
              </Suspense>
            }
          />
          <Route
            path="submittedrequests"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <SubmittedRequests />
              </Suspense>
            }
          />
          <Route
            path="results"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <Results />
              </Suspense>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default App;