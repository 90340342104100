// config.js
const environments = {
    1: "http://localhost:3000",
    2: "https://v2backend-dot-listalot-440122.uc.r.appspot.com",
    3: "https://devbackend.listornot.com",
  };
  
  const selectedEnv = 3;
  
  const config = {
    API_BASE_URL: environments[selectedEnv],
  };
  
  export default config;