import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../Config/config"; // Exports API_BASE_URL
import { ArrowBack } from "@mui/icons-material";
import {
  IconButton,
  Button,
  TextField,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Resizer from "react-image-file-resizer";
import ToastAlert, { showToast } from "../Common/ToastAlert";

function SubmitRequest() {
  const navigate = useNavigate();
  // Initialize state with localStorage data if available.
  const [name, setName] = useState(localStorage.getItem("name") || "");
  const [email, setEmail] = useState(localStorage.getItem("emailsubmit") || "");
  const [phone, setPhone] = useState(localStorage.getItem("phone") || "");
  const [subscriptionId, setSubscriptionId] = useState(
    localStorage.getItem("subscriptionId") || ""
  );
  const [customDescriptionTone, setCustomDescriptionTone] = useState(
    localStorage.getItem("customDescriptionTone") || ""
  );
  const [customTitleTone, setCustomTitleTone] = useState(
    localStorage.getItem("customTitleTone") || ""
  );
  const [initialLotNumber, setInitialLotNumber] = useState(
    localStorage.getItem("initialLotNumber") || ""
  );
  const [additionalInfo, setAdditionalInfo] = useState(
    localStorage.getItem("additionalInfo") || ""
  );
  const [podName, setPodName] = useState(localStorage.getItem("podName") || "");
  const [images, setImages] = useState([]);
  const [progress, setProgress] = useState(0); // used for resizing progress
  const [resizing, setResizing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // new state for upload progress

  // Save each field to localStorage when it changes.
  useEffect(() => { localStorage.setItem("name", name); }, [name]);
  useEffect(() => { localStorage.setItem("emailsubmit", email); }, [email]);
  useEffect(() => { localStorage.setItem("phone", phone); }, [phone]);
  useEffect(() => { localStorage.setItem("subscriptionId", subscriptionId); }, [subscriptionId]);
  useEffect(() => { localStorage.setItem("customDescriptionTone", customDescriptionTone); }, [customDescriptionTone]);
  useEffect(() => { localStorage.setItem("customTitleTone", customTitleTone); }, [customTitleTone]);
  useEffect(() => { localStorage.setItem("initialLotNumber", initialLotNumber); }, [initialLotNumber]);
  useEffect(() => { localStorage.setItem("additionalInfo", additionalInfo); }, [additionalInfo]);
  useEffect(() => { localStorage.setItem("podName", podName); }, [podName]);

  // Resize a single image and update progress
  const resizeImage = (file, index, total) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600, // width
        600, // height
        "JPEG", // format
        70, // quality
        0, // rotation
        (uri) => {
          const percent = Math.round(((index + 1) / total) * 100);
          setProgress(percent);
          resolve(uri);
        },
        "file" // Return type as File object
      );
    });

  // When files are selected, resize them first
  const handleFileChange = async (e) => {
    setProgress(0);
    const files = Array.from(e.target.files);
    if (!files.length) return;
    setResizing(true);
    try {
      const resizedFiles = await Promise.all(
        files.map((file, index) => resizeImage(file, index, files.length))
      );
      setImages(resizedFiles);
      showToast("Images resized successfully!", "success");
    } catch (error) {
      console.error("Image resizing failed:", error);
      showToast("Failed to resize images.", "error");
    } finally {
      setResizing(false);
    }
  };


  const uploadFilesInBatches = async (files, batchSize = 250) => {
    const uploadedUrls = [];
    const totalBatches = Math.ceil(files.length / batchSize);
    for (let i = 0; i < files.length; i += batchSize) {
      const batch = files.slice(i, i + batchSize);
      const currentBatchNumber = Math.floor(i / batchSize) + 1;
      const formData = new FormData();
      batch.forEach((file) => {
        formData.append("images", file);
      });
      try {
        const response = await fetch(`${config.API_BASE_URL}/upload-files`, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        // Append the URLs returned by this batch
        uploadedUrls.push(...data.imageUrls);
      } catch (error) {
        console.error("Error uploading batch:", error);
        showToast("Error uploading some images.", "error");
      }
      // Update upload progress based on batches processed
      const percentage = Math.round((currentBatchNumber / totalBatches) * 100);
      setUploadProgress(percentage);
    }
    return uploadedUrls;
  };

  // Final submission handler: first upload images then submit metadata
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    showToast("Submitting request...", "info");
    try {
      // Optional delay for user feedback
      await new Promise((resolve) => setTimeout(resolve, 3000));

      // Upload images in batches first
      const uploadedUrls = await uploadFilesInBatches(images, 250);

      // Prepare final form data including all metadata and the image URLs
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("podName", podName);
      formData.append("subscriptionId", subscriptionId);
      formData.append("customDescriptionTone", customDescriptionTone);
      formData.append("customTitleTone", customTitleTone);
      formData.append("initialLotNumber", initialLotNumber);
      formData.append("additionalInfo", additionalInfo);
      formData.append("uploadedImages", JSON.stringify(uploadedUrls));

      const response = await fetch(`${config.API_BASE_URL}/submit-request`, {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
      showToast("Request submitted successfully!", "success");
      // Redirect after successful submission
      navigate("/");
    } catch (error) {
      console.error("Final submission error:", error);
      showToast("Failed to submit request.", "error");
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <ToastAlert />
      <div className="flex items-center mb-6">
        <IconButton onClick={() => navigate("/")}>
          <ArrowBack />
        </IconButton>
        <h1 className="text-2xl font-bold ml-2 text-primary">Submit Request</h1>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <TextField
          label="Pod Name"
          variant="outlined"
          fullWidth
          value={podName}
          onChange={(e) => setPodName(e.target.value)}
          required
        />
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Phone Number"
          type="tel"
          variant="outlined"
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
        <TextField
          label="Subscription ID (if known)"
          variant="outlined"
          fullWidth
          value={subscriptionId}
          onChange={(e) => setSubscriptionId(e.target.value)}
        />
        <TextField
          label="Custom Description Tone"
          variant="outlined"
          fullWidth
          value={customDescriptionTone}
          onChange={(e) => setCustomDescriptionTone(e.target.value)}
        />
        <TextField
          label="Custom Title Tone"
          variant="outlined"
          fullWidth
          value={customTitleTone}
          onChange={(e) => setCustomTitleTone(e.target.value)}
        />
        <TextField
          label="Initial Lot Number"
          type="number"
          variant="outlined"
          fullWidth
          value={initialLotNumber}
          onChange={(e) => setInitialLotNumber(e.target.value)}
          required
        />
        <TextField
          label="Additional Info About the Pod"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
        />
        <div>
          <label className="block text-sm font-medium text-primary">
            Images (resized before upload):
          </label>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="mt-1 block"
            disabled={resizing}
          />
        </div>
        {resizing && (
          <div className="mt-2">
            <h1 className="text-center">Resizing Images. Please wait...</h1>
            <p className="text-center text-gray-600 font-semibold">{progress}% Completed</p>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        )}
        <button
        className={`w-full  py-3 text-white rounded-md  ${resizing || images.length === 0 ? "bg-gray-500 bg-opacity-80" : "bg-primary"}`}
          type="submit"

          disabled={resizing || images.length === 0}
        >
          Submit Request
        </button>
      </form>
      <Dialog open={isSubmitting}>
      <h1 className="text-xl font-semibold text-center mt-4 text-primary">Submitting request...</h1>
        <DialogContent>
          <DialogContentText>
            <h1 className="text-primary text-center font-semibold">Please wait while we submit your request... </h1>
           <h1 className="text-center font-semibold my-2">{uploadProgress}% completed.</h1> 
          </DialogContentText>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SubmitRequest;