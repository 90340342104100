import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../Config/firebase";
import axios from "axios";
import logo from "../../assets/logo.png";

import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  IconButton,
  Box,
  useMediaQuery,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SaveIcon from "@mui/icons-material/Save";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CampaignIcon from "@mui/icons-material/Campaign";
import SettingsIcon from "@mui/icons-material/Settings";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import config from "../Config/config";

const drawerWidth = 260;

// Sidebar container styling.
const SidebarContainer = styled("div")(() => ({
  display: "flex",
}));

// Sidebar header styling.
const SidebarHeader = styled("div")(({ sidebarOpen }) => ({
  display: "flex",
  flexDirection: sidebarOpen ? "column" : "row",
  alignItems: "center",
  justifyContent: sidebarOpen ? "center" : "space-between",
  padding: sidebarOpen ? 16 : 8,
  backgroundColor: "#333399",
  color: "#fff",
  textAlign: "center",
}));

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = localStorage.getItem("userId");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [tokens, setTokens] = useState(0);
  const [username, setUsername] = useState("");
  const [permissions, setPermissions] = useState({});

  // Check if mobile view based on screen width.
  const isMobile = useMediaQuery("(max-width:600px)");

  // Fetch user details (including token balance and permissions)
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}/get-user-details?userId=${userId}`
        );
        const { tokens, username, permissions } = response.data;
        setTokens(tokens || 0);
        setUsername(username);
        setPermissions(permissions || {}); // Store permissions from backend
        localStorage.setItem("tokens", tokens);
      } catch (error) {
        console.error("Error fetching user details:", error.message);
      }
    };

    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  // Logout function.
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // Common sidebar content used by both desktop and mobile.
  const sidebarContent = (
    <Box className="flex flex-col h-screen justify-between">
      <Box>
        <List>
          {permissions.superAdmin && (
            <ListItem disablePadding key="admin">
              <ListItemButton
                onClick={() => {
                  navigate("/admin");
                  if (isMobile) setMobileDrawerOpen(false);
                }}
              >
                <AdminPanelSettingsIcon sx={{ marginRight: sidebarOpen ? 1 : 0 }} />
                {sidebarOpen && <ListItemText primary="Admin" />}
              </ListItemButton>
            </ListItem>
          )}
          {(permissions.superAdmin || permissions.submitRequest) && (
            <ListItem disablePadding key="submittedrequest">
              <ListItemButton
                onClick={() => {
                  navigate("/submittedrequests");
                  if (isMobile) setMobileDrawerOpen(false);
                }}
              >
                <RequestPageIcon sx={{ marginRight: sidebarOpen ? 1 : 0 }} />
                {sidebarOpen && <ListItemText primary="Submitted Requests" />}
              </ListItemButton>
            </ListItem>
          )}
          {[
            {
              key: "catalogPhotos",
              text: "Catalog with Photos",
              icon: <CameraAltIcon />,
              path: "/createpods",
            },
            {
              key: "catalogSpreadsheet",
              text: "Catalog with Spreadsheet",
              icon: <SaveIcon />,
              path: "/barcodelookup",
            },
            {
              key: "buymoretokens",
              text: "Buy More Tokens",
              icon: <ShoppingCartIcon />,
              path: "/usersettings",
              state: "tokens",
            },
            {
              key: "createAiTone",
              text: "Create/Edit AI Tone",
              icon: <CampaignIcon />,
              path: "/usersettings",
              state: "settings",
            },
            {
              key: "userSettings",
              text: "Settings",
              icon: <SettingsIcon />,
              path: "/usersettings",
              state: "profile",
            },
            {
              key: "support",
              text: "Support",
              icon: <WarningAmberIcon />,
              path: "https://meetings.hubspot.com/jeff1270",
              external: true,
            },
          ].map(({ key, text, icon, path, external, state }) => (
            <ListItem disablePadding key={key}>
              <ListItemButton
                selected={
                  path === "/usersettings"
                    ? location.state?.activeSection === state
                    : location.pathname === path
                }
                onClick={() =>
                  external
                    ? window.open(path, "_blank")
                    : (() => {
                      navigate(path, { state: { activeSection: state } });
                      if (isMobile) setMobileDrawerOpen(false);
                    })()
                }
              >
                {React.cloneElement(icon, {
                  sx: { marginRight: sidebarOpen ? 1 : 0 },
                })}
                {sidebarOpen && <ListItemText primary={text} />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      {/* Logout Button */}
      <Box>
        <ListItem disablePadding key="logout">
          <ListItemButton onClick={handleLogout}>
            <HighlightOffIcon sx={{ marginRight: sidebarOpen ? 1 : 0 }} />
            {sidebarOpen && <ListItemText primary="Logout" />}
          </ListItemButton>
        </ListItem>
      </Box>
    </Box>
  );

  // Desktop Sidebar: permanent drawer.
  const desktopSidebar = (
    <Drawer
      variant="permanent"
      sx={{
        width: sidebarOpen ? drawerWidth : 80,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sidebarOpen ? drawerWidth : 80,
          transition: "width 0.3s ease-in-out",
          boxSizing: "border-box",
          backgroundColor: "#333399",
          color: "#fff",
          overflowX: "hidden",
        },
      }}
    >
      <SidebarHeader sidebarOpen={sidebarOpen}>
        <div className="flex flex-row justify-between">
          {sidebarOpen && (
            <img
              onClick={() => navigate("/")}
              src={logo}
              alt="Logo"
              style={{
                width: 150,
                height: 150,
                borderRadius: "100%",
                marginBottom: 8,
                boxShadow: "0 10px 12px rgba(0, 0, 0, 0.1)",
              }}
            />
          )}
          <IconButton onClick={() => setSidebarOpen(!sidebarOpen)} sx={{ color: "#fff" }}>
            {sidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
      </SidebarHeader>
      <Divider sx={{ backgroundColor: "#fff" }} />
      {sidebarContent}
    </Drawer>
  );

  // Mobile Sidebar: hamburger menu with temporary drawer.
  const mobileSidebar = (
    <>
      {!mobileDrawerOpen &&
        <IconButton
          onClick={() => setMobileDrawerOpen(true)}
          sx={{
            position: "fixed",
            top: 16,
            left: 16,
            zIndex: 1300,
            backgroundColor: "#333399",
            color: "#fff",
          }}
        >
          <MenuIcon />
        </IconButton>
      }
 
      <Drawer
        anchor="left"
        open={mobileDrawerOpen}
        onClose={() => setMobileDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#333399",
            color: "#fff",
          },
        }}
      >
        <SidebarHeader sidebarOpen={true}>
          <div className="flex flex-row justify-between" style={{ width: "100%" }}>
            <img
              onClick={() => {
                navigate("/");
                setMobileDrawerOpen(false);
              }}
              src={logo}
              alt="Logo"
              style={{
                width: 150,
                height: 150,
                borderRadius: "100%",
                marginBottom: 8,
                boxShadow: "0 10px 12px rgba(0, 0, 0, 0.1)",
              }}
            />
            <IconButton onClick={() => setMobileDrawerOpen(false)} sx={{ color: "#fff" }}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
        </SidebarHeader>
        <Divider sx={{ backgroundColor: "#fff" }} />
        {sidebarContent}
      </Drawer>
    </>
  );

  return (
    <SidebarContainer>
      {isMobile ? mobileSidebar : desktopSidebar}
    </SidebarContainer>
  );
};

export default Sidebar;