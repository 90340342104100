import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastAlert = () => {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000} // Auto close after 5 seconds
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

// showToast function with normal Toastify styles
export const showToast = (message, type = "default") => {
  toast(message, { type }); // Supports "success", "error", "warning", "info", "default"
};

export default ToastAlert;